import React, { useState, useEffect } from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCheckbox,
  MDBIcon,
} from 'mdb-react-ui-kit';
import './ForgetPassword.css';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
function ForgetPassword() {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loginLoader, setLoginLoader] = useState(false);
  const [role, setRole] = useState('Company');
  const token = localStorage.getItem('Token');

  const authLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      try {
        setLoginLoader(true);

        const userDetails = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse?.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${tokenResponse?.access_token}`,
              Accept: 'application/json',
            },
          },
        );
        console.log('user details= ', userDetails);
        const payload = {
          email: userDetails?.data?.email,
          google_id: userDetails?.data?.id,
          name: userDetails?.data?.name,
          verified_email: userDetails?.data?.verified_email,
          role: role,
        };
        console.log(payload);
        const { data } = await axios.post(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_OAUTH_URL}`,
          payload,
          {
            withCredentials: true,
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );
        console.log(data);
        setLoginLoader(false);

        localStorage.setItem('Token', data?.token);
        localStorage.setItem('role', data?.role);
        navigate('/admin');
      } catch (error) {
        console.log('auth error ', error);
        setLoginLoader(false);
      }
    },
    onError: (error) => {
      console.log('Login Failed ', error);
      setLoginLoader(false);
    },
  });
  useEffect(() => {
    if (token) {
      navigate('/admin/default');
    }
  }, []);
  const signup = async (e) => {
    // e.preventDefault();
    setLoginLoader(true);
    try {
      const body = {
        name: name,
        email: email,
        role: role,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      };
      console.log(body);

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SIGN_UP}`,
        body,
        {
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log(data);
      setLoginLoader(false);
      toast.success('Please Verify Your Email');
      navigate('/login');
    } catch (error) {
      console.log(error);
      setLoginLoader(false);
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Internal Sever Error');
      }
    }
  };
  return (
    <MDBContainer
      fluid
      className="p-4 background-radial-gradient h-full overflow-hidden"
      style={{ height: 'auto' }}
    >
      <Toaster position="top-center" reverseOrder={false} />
      <MDBRow>
        <MDBCol
          md="6"
          className="text-center text-md-start d-flex flex-column justify-content-center"
        >
          <h1
            className="my-5 display-3 fw-bold ls-tight px-3"
            style={{ color: 'hsl(218, 81%, 95%)' }}
          >
            The best offer <br />
            <span style={{ color: 'hsl(218, 81%, 75%)' }}>
              for your business
            </span>
          </h1>

          <p className="px-3" style={{ color: 'hsl(218, 81%, 85%)' }}>
            Join us today! Create an account to unlock exclusive features and
            take your experience to the next level. Already have an
            account?&nbsp;
            <a
              href="/login"
              style={{
                color: 'hsl(218, 81%, 95%)',
                textDecoration: 'underline',
              }}
            >
              Log in
            </a>
            &nbsp;to continue your journey.
          </p>
        </MDBCol>

        <MDBCol md="6" className="position-relative">
          <div
            id="radius-shape-1"
            className="position-absolute rounded-circle shadow-5-strong"
          ></div>
          <div
            id="radius-shape-2"
            className="position-absolute shadow-5-strong"
          ></div>

          <MDBCard className="my-3 bg-glass">
            <MDBCardBody className="" style={{ padding: '65px 80px' }}>
            <div
                className=" fw-bold mb-4  "
                style={{ color: 'hsl(218, 81%, 75%)', fontSize: '25px' }}
              >
                Forget Password
              </div>
              <br/>
              {/* <MDBRow>
                <MDBCol col="6">
                  <MDBInput
                    wrapperClass="mb-4"
                    label="First name"
                    id="form1"
                    type="text"
                  />
                </MDBCol>

                <MDBCol col="6">
                  <MDBInput
                    wrapperClass="mb-4"
                    label="Last name"
                    id="form2"
                    type="text"
                  />
                </MDBCol>
              </MDBRow>*/}
              {/* <MDBInput
                wrapperClass="mb-4"
                label="Full Name"
                id="form1"
                type="text"
                onChange={(e) => setName(e.target.value)}
              />
              <br /> */}
              <MDBInput
                wrapperClass="mb-4"
                label="Email"
                id="form2"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />

              <MDBBtn className="w-50 " size="md" >
                Verify Email
              </MDBBtn>
              <br />
              <br />

              <MDBInput
                wrapperClass="mb-4"
                label="New Password"
                id="form3"
                type="password"
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <br />
              <MDBInput
                wrapperClass="mb-4"
                label="Confirm Password"
                id="form3"
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <br />
              {/*  <div className="d-flex justify-content-center mb-4">
                <MDBCheckbox
                  name="flexCheck"
                  value=""
                  id="flexCheckDefault"
                  label="Subscribe to our newsletter"
                />
              </div>*/}

              <MDBBtn className="w-100 mb-4" size="md" onClick={() => signup()}>
                {loginLoader ? 'Processing....' : 'Go to Login'}
              </MDBBtn>
              <br />

              
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default ForgetPassword;
