// import React, { useState } from "react";
// import Navbar from "../Navbar/Navbar";
// import './PrivacyPolicy.css'; // Assuming you have some CSS for styling
// import Footer from '../Footer/Footer';
// import { Link } from "react-router-dom";


// const PrivacyPolicy = () => {
//      const [isChecked, setIsChecked] = useState(false);
  

//   const handleCheckboxChange = () => {
//     setIsChecked(!isChecked);
//   };
//   return (
//     <div>
//       <Navbar />
//       <div className="privacy-container">
//       <div className="privacy-header">
//         <h1>Privacy Policy</h1>
//       </div>
//       <br/>
//         {/* <h1>Privacy Policy</h1> */}
//         {/* <p>Last updated: [Date]</p> */}
//         <p>Welcome to CareerIQ. AI!</p>
//         <p>
//           CareerIQ. AI ("we", "our", "us") is committed to protecting and respecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by CareerIQ. AI.
//         </p>
//         <p>
//           By accessing or using our website, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service.
//         </p>
//         <h2>Information We Collect</h2>
//         <p>
//           We collect information from you when you visit our site, register on our site, place an order, subscribe to our newsletter, respond to a survey, fill out a form, or enter information on our site.
//         </p>
//         <ul>
//           <li><strong>Personal Identification Information:</strong> Name, email address, mailing address, phone number, or other details to help you with your experience.</li>
//           <li><strong>Interview Data:</strong> Video recordings, audio recordings, responses to interview questions, and any other information you provide during the interview process.</li>
//           <li><strong>Usage Data:</strong> Information on how you access and use our website.</li>
//           <li><strong>Cookies and Tracking Technologies:</strong> Cookies, web beacons, and other tracking technologies to collect information about your interactions with our site.</li>
//         </ul>
//         <h2>How We Use Your Information</h2>
//         <p>
//           We use the information we collect in the following ways:
//         </p>
//         <ul>
//           <li>To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
//           <li>To improve our website in order to better serve you.</li>
//           <li>To allow us to better service you in responding to your customer service requests.</li>
//           <li>To administer a contest, promotion, survey, or other site feature.</li>
//           <li>To quickly process your transactions.</li>
//           <li>To ask for ratings and reviews of services or products.</li>
//           <li>To follow up with them after correspondence (live chat, email, or phone inquiries).</li>
//         </ul>
//         <h2>How We Protect Your Information</h2>
//         <p>
//           Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible. We use regular Malware Scanning.
//         </p>
//         <p>
//           Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive information you supply is encrypted via Secure Socket Layer (SSL) technology.
//         </p>
//         <h2>Sharing Your Information</h2>
//         <p>
//           We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property or safety.
//         </p>
//         <h2>Your Consent</h2>
//         <p>
//           By using our site, you consent to our website privacy policy.
//         </p>
//         <h2>Changes to Our Privacy Policy</h2>
//         <p>
//           If we decide to change our privacy policy, we will post those changes on this page. Policy changes will apply only to information collected after the date of the change.
//         </p>
//         <h2>Contacting Us</h2>
//         <p>
//           If there are any questions regarding this privacy policy, you may contact us using the information below:
//         </p>
       
//         <div>      
//       <div className="terms-action">
//           <div>
//             <input
//               type="checkbox"
//               id="acceptTerms"
//               checked={isChecked}
//               onChange={handleCheckboxChange}
//             />
//           </div>
//           <div>
//             <label htmlFor="acceptTerms">
//               {" "}
//               I accept the terms and conditions
//             </label>
//           </div>
//         </div>
//         <button className="accept-button"  disabled={!isChecked}><Link to={"/"}>Accept</Link></button>
//       </div>
//       </div>
     
//       <Footer/>
//     </div>
//   );
// }

// export default PrivacyPolicy;

import React, { useState } from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import { Container, ContentWithPaddingXl } from "../misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
// import Header from "../../Components/headers/light";
import Footer from "../footers/MiniCenteredFooter";
import { SectionHeading } from "../misc/Headings";
import { Link } from "react-router-dom";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const PrivacyPolicy = ({ headingText = "Privacy Policy" }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <AnimationRevealPage>
      {/* <Header /> */}
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: April 21, 2048</p>

            <p>
              This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
              information when You use the Service and tells You about Your privacy rights and how the law protects You.
            </p>

            <p>
              We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
              collection and use of information in accordance with this Privacy Policy.
            </p>

            <h1>Interpretation and Definitions</h1>
            <h2>Interpretation</h2>
            <p>
              The words of which the initial letter is capitalized have meanings defined under the following conditions.
            </p>
            <p>
              The following definitions shall have the same meaning regardless of whether they appear in singular or in
              plural.
            </p>

            <h2>Definitions</h2>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
              <li>
                <p>
                  <strong>You</strong> means the individual accessing or using the Service, or the company, or other
                  legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                </p>
              </li>
              <li>
                <p>
                  <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
                  refers to CareerIQ. AI AI Video Interview.
                </p>
              </li>
              <li>
                <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control
                with a party, where "control" means ownership of 50% or more of the shares, equity interest or other
                securities entitled to vote for election of directors or other managing authority.
              </li>
              <li>
                <strong>Account</strong> means a unique account created for You to access our Service or parts of our
                Service.
              </li>
              <li>
                <strong>Website</strong> refers to CareerIQ. AI AI Video Interview, accessible from https://CareerIQ. AI.com
              </li>{" "}
              <li>
                <strong>Service</strong> refers to the Website.
              </li>
              <li>
                <strong>Country</strong> refers to: Canada
              </li>
              <li>
                <p>
                  <strong>Service Provider</strong> means any natural or legal person who processes the data on behalf
                  of the Company. It refers to third-party companies or individuals employed by the Company to
                  facilitate the Service, to provide the Service on behalf of the Company, to perform services related
                  to the Service or to assist the Company in analyzing how the Service is used.
                </p>
              </li>
              <li>
                <strong>Third-party Social Media Service</strong> refers to any website or any social network website
                through which a User can log in or create an account to use the Service.
              </li>
              <li>
                <p>
                  <strong>Personal Data</strong> is any information that relates to an identified or identifiable
                  individual.
                </p>
              </li>
              <li>
                <strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other
                device by a website, containing the details of Your browsing history on that website among its many
                uses.
              </li>{" "}
              <li>
                <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the
                Service or from the Service infrastructure itself (for example, the duration of a page visit).
              </li>
            </ul>

            <h1>Collecting and Using Your Personal Data</h1>
            <h2>Types of Data Collected</h2>

            <h3>Personal Data</h3>
            <p>
              While using Our Service, We may ask You to provide Us with certain personally identifiable information
              that can be used to contact or identify You. Personally identifiable information may include, but is not
              limited to:
            </p>
            <ul>
              <li>Email address</li> <li>First name and last name</li> <li>Phone number</li>{" "}
              <li>Address, State, Province, ZIP/Postal code, City</li> <li>Usage Data</li>
            </ul>

            <h3>Usage Data</h3>
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>
              Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
              browser type, browser version, the pages of our Service that You visit, the time and date of Your visit,
              the time spent on those pages, unique device identifiers and other diagnostic data.
            </p>
            <p>
              When You access the Service by or through a mobile device, We may collect certain information
              automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique
              ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet
              browser You use, unique device identifiers and other diagnostic data.
            </p>
            <p>
              We may also collect information that Your browser sends whenever You visit our Service or when You access
              the Service by or through a mobile device.
            </p>

            <h3>Tracking Technologies and Cookies</h3>
            <p>
              We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
              information. Tracking technologies used are beacons, tags, and scripts to collect and track information
              and to improve and analyze Our Service.
            </p>
            <p>
              You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However,
              if You do not accept Cookies, You may not be able to use some parts of our Service.
            </p>
            <p>
              Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or
              mobile device when You go offline, while Session Cookies are deleted as soon as You close your web
              browser. Learn more about cookies: <a href="https://www.termsfeed.com/blog/cookies/">All About Cookies</a>
              .
            </p>
            <p>We use both session and persistent Cookies for the purposes set out below:</p>
            <ul>
              <li>
                <p>
                  <strong>Necessary / Essential Cookies</strong>
                </p>
                <p>Type: Session Cookies</p>
                <p>Administered by: Us</p>
                <p>
                  Purpose: These Cookies are essential to provide You with services available through the Website and to
                  enable You to use some of its features. They help to authenticate users and prevent fraudulent use of
                  user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We
                  only use these Cookies to provide You with those services.
                </p>
              </li>
              <li>
                <p>
                  <strong>Cookies Policy / Notice Acceptance Cookies</strong>
                </p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Us</p>
                <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
              </li>
              <li>
                <p>
                  <strong>Functionality Cookies</strong>
                </p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Us</p>
                <p>
                  Purpose: These Cookies allow us to remember choices You make when You use the Website, such as
                  remembering your login details or language preference. The purpose of these Cookies is to provide You
                  with a more personal experience and to avoid You having to re-enter your preferences every time You
                  use the Website.
                </p>
              </li>
            </ul>

            <h2>Use of Your Personal Data</h2>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul>
              <li>To provide and maintain our Service, including to monitor the usage of our Service.</li>{" "}
              <li>To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You
                provide can give You access to different functionalities of the Service that are available to You as a
                registered user.
              </li>
              <li>
                For the performance of a contract: the development, compliance and undertaking of the purchase contract
                for the products, items or services You have purchased or of any other contract with Us through the
                Service.
              </li>
              <li>To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of
                electronic communication, such as a mobile application's push notifications regarding updates or
                informative communications related to the functionalities, products or contracted services, including
                the security updates, when necessary or reasonable for their implementation.
              </li>
              <li>To provide You with news, special offers and general information about other goods, services and
                events which we offer that are similar to those that you have already purchased or enquired about
                unless You have opted not to receive such information.
              </li>
              <li>To manage Your requests: To attend and manage Your requests to Us.</li>{" "}
              <li>For business transfers: We may use Your information to evaluate or conduct a merger, divestiture,
                restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets,
                whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which
                Personal Data held by Us about our Service users is among the assets transferred.
              </li>
              <li>For other purposes: We may use Your information for other purposes, such as data analysis, identifying
                usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve
                our Service, products, services, marketing and your experience.
              </li>
            </ul>

            <h2>Retention of Your Personal Data</h2>
            <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in
              this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our
              legal obligations (for example, if we are required to retain your data to comply with applicable laws),
              resolve disputes, and enforce our legal agreements and policies.</p>{" "}
            <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained
              for a shorter period of time, except when this data is used to strengthen the security or to improve the
              functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>

            <h2>Transfer of Your Personal Data</h2>
            <p>Your information, including Personal Data, is processed at the Company's operating offices and in any
              other places where the parties involved in the processing are located. It means that this information may
              be transferred to — and maintained on — computers located outside of Your state, province, country or
              other governmental jurisdiction where the data protection laws may differ than those from Your
              jurisdiction.</p>{" "}
            <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your
              agreement to that transfer.</p>{" "}
            <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
              accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an
              organization or a country unless there are adequate controls in place including the security of Your data
              and other personal information.</p>

            <h2>Disclosure of Your Personal Data</h2>
            <h3>Business Transactions</h3>
            <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred.
              We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy
              Policy.</p>{" "}
            <h3>Law enforcement</h3>
            <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do
              so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>{" "}
            <h3>Other legal requirements</h3>
            <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
            <ul>
              <li>Comply with a legal obligation</li> <li>Protect and defend the rights or property of the Company</li>{" "}
              <li>Prevent or investigate possible wrongdoing in connection with the Service</li>{" "}
              <li>Protect the personal safety of Users of the Service or the public</li>{" "}
              <li>Protect against legal liability</li>
            </ul>

            <h2>Security of Your Personal Data</h2>
            <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over
              the Internet, or method of electronic storage is 100% secure. While We strive to use commercially
              acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>

            <h1>Children's Privacy</h1>
            <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally
              identifiable information from anyone under the age of 13. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We
              have collected Personal Data from anyone under the age of 13 without verification of parental consent, We
              take steps to remove that information from Our servers.</p>{" "}
            <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires
              consent from a parent, We may require Your parent's consent before We collect and use that information.</p>

            <h1>Links to Other Websites</h1>
            <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third
              party link, You will be directed to that third party's site. We strongly advise You to review the Privacy
              Policy of every site You visit.</p>{" "}
            <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any
              third party sites or services.</p>

            <h1>Changes to this Privacy Policy</h1>
            <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new
              Privacy Policy on this page.</p>{" "}
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
              Policy are effective when they are posted on this page.</p>

            <h1>Contact Us</h1>
            <p>If you have any questions about this Privacy Policy, You can contact us:</p>
            <ul>
              <li>By email: contact@CareerIQ. AI.com</li> <li>By visiting this page on our website: https://CareerIQ. AI.com</li>{" "}
              <li>By phone number: +1234567890</li>
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

export default PrivacyPolicy;