import React, { useEffect, useLayoutEffect } from 'react';
import './assets/css/App.css';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import AOS from 'aos';
import GLightbox from 'glightbox';
import Swiper from 'swiper';
import PureCounter from '@srexi/purecounterjs';
import {} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import Home from './NewHome/NewHomePage';
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import 'aos/dist/aos.css';
import 'glightbox/dist/css/glightbox.min.css';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';
import Login from 'components/Login/Login';
import SignUp from './components/SignUp/SignUp';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import TermsAndCondition from './components/TermsAndCondition/TermsAndCondition';
import SecurityCenter from 'components/SecurityCenter/SecurityCenter';
import VideoCard from 'components/VideosCard/VideoCard';
import Interview from 'components/Interview/Interview';
import { jwtDecode } from 'jwt-decode';
import VideoAssessment from 'components/VideoAssessment/VideoAssessment';
import axios from 'axios';
import ForgetPassword from 'components/ForgetPassword/ForgetPassword';

// Chakra imports
export const Server = process.env.REACT_APP_SERVER;


export default function Main() {
  const navigate = useNavigate();
  useEffect(() => {
    // Initialize each plugin
    AOS.init();
    GLightbox();
    new Swiper('.swiper-container', {
      // Swiper options
    });
    new PureCounter();
  }, []);
  let token = localStorage.getItem('Token');

  const logout = () => {
    if (token) {
      localStorage.removeItem('Token');
      localStorage.removeItem('role');
      window.location.href = '/login';
      navigate('/login');
    }

    // dispatch(tokenActions.changeToken({ token: null }));
    // dispatch(roleActions.changeRole({ role: null }));
    // setTimeout(() => {
    //   window.location.href = '/login';
    //   // window.location.reload();
    // }, [500]);
  };
  useEffect(() => {
    if (token && isTokenExpired(token)) {
      logout();
      return;
    }
  }, []);
  const isTokenExpired = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // in seconds
      console.log(decodedToken.exp < currentTime);
      console.log('token expired', decodedToken.exp < currentTime);
      return decodedToken.exp < currentTime;
    } catch (error) {
      return true;
    }
  };

  useLayoutEffect(() => {
    const getRefershToken = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH}`,
          {
            withCredentials: true,
            credentials: 'include',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
            },
          },
        );
        console.log(data);
      } catch (error) {
        console.log('error', error);
      }
    };
    getRefershToken();
  }, []);
  // eslint-disable-next-line
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  return (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgetPassword" element={<ForgetPassword />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/termsAndCondition" element={<TermsAndCondition />} />
        <Route path="/securityCenter" element={<SecurityCenter />} />
        <Route path="/interview" element={<Interview />} />
        <Route
              path="/videoAssessment/:userId/:id/:jobId"
              element={<VideoAssessment />}
            />
        <Route path="auth/*" element={<AuthLayout />} />
        <Route
          path="admin/*"
          element={
            <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route
          path="rtl/*"
          element={
            <RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route path="/" element={<Navigate to="/admin" replace />} />
      </Routes>
    </ChakraProvider>
  );
}
